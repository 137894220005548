// src/Camera.js
import React ,{lazy} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";


import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
// import "bootstrap/dist/js/bootstrap.bundle.min";

import './App.css';
//Analytics
// import amplitude from 'amplitude-js';
import { initAnalytics } from './utilities/analytics.js';


import Container from './layouts/Container';

import PageLanding from './pages/PageLanding';
import PageHint from './pages/PageHint';
import PageCaptured from './pages/PageCaptured';
import PageCapturedPalette from './pages/PageCapturedPalette';
import PageUnderTone1 from './pages/PageUnderTone1';
import PageUnderTone2 from './pages/PageUnderTone2';
import PageUnderTone3 from './pages/PageUnderTone3';
import PageSeasonal1 from './pages/PageSeasonal1';
import PageSeasonal2 from './pages/PageSeasonal2';
import PageSeasonal3 from './pages/PageSeasonal3';
import PageResult from './pages/PageResult';
import PageResultTemplate from './pages/PageResultTemplate';

initAnalytics()
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<PageLanding/>} />
          <Route path="/:type/hint" element={<Container className='container-hint'><PageHint/></Container>} />
          <Route path="/:type/captured" element={<Container><PageCaptured/></Container>} />
          <Route path="/:type/palette" element={<Container><PageCapturedPalette/></Container>} />
          <Route path="/:type/undertone-1" element={<Container><PageUnderTone1/></Container>} />
          <Route path="/:type/undertone-2" element={<Container><PageUnderTone2/></Container>} />
          <Route path="/:type/undertone-3/" element={<Container><PageUnderTone3/></Container>} />

          {/* EXPERT */}
          <Route path="/:type/seasonal-1" element={<Container><PageSeasonal1/></Container>} />
          <Route path="/:type/seasonal-2/" element={<Container><PageSeasonal2/></Container>} />
          <Route path="/:type/seasonal-3/" element={<Container><PageSeasonal3/></Container>} />
          {/* END EXPERT */}

          <Route exact path="/basic" element={<Navigate replace to="/basic/hint" />} />
          <Route exact path="/expert" element={<Navigate replace to="/expert/hint" />} />

          <Route path="/:type/result" element={<PageResult/>} />
          <Route path="/:type/result-template" element={<PageResultTemplate/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
