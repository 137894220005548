import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({title, className}) => {
  return (
    <>
      <header id="header" className={`d-flex ${title ? 'justify-content-between' : 'justify-content-center'} align-items-end ${className}`}>
        {title ?
          <span>{title}</span>
          :
          null
        }
        <Link to='/'>
          <img className='logo-header' src='/images/logo.png' alt=''/>
        </Link>
      </header>
    </>
  );
};

export default Header;
