import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate ,useLocation} from 'react-router-dom';
import { BiSolidLeftArrow, BiX } from 'react-icons/bi';


import Header from "../components/Header";
import Carousel from "../components/Carousel";
import Step from "../components/Step";
import SideInfo from "../components/SideInfo";

import data from '../json/palette.json'
import { sendAnalyticsData } from '../utilities/analytics';

const PageSeasonal2 = () => {


  let paletteData = [];

  const [palette, setPalette] = useState([]);
  const [checkedPalette, setCheckedPalette] = useState({});
  const [dataImage, setDataImage] = useState(null);
  const [indexSelectedPalette,setIndexSelectedPalette ] = useState(0);
  const [sectionDisabled, setSectionDisabled] = useState([2, 3]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [seasonalState, setSeasonalState] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideChanged, setSlideChanged] = useState(false);
  
  const refSlider = useRef()

  const navigate = useNavigate();
  const location = useLocation()
  const { type } = useParams();

  const switchSectionColor = (name, index) =>{
    // clearTimeout(transitionSlide);
    setCheckedPalette({"name": name, "palette": palette[index].palette});
    setButtonDisabled(false);
    setIndexSelectedPalette(index);
    setSlideChanged(false);
    
    setTimeout(() => {
      setCurrentSlide(currentSlide);
      refSlider.current.slickGoTo(currentSlide);
      setSlideChanged(true);
    }, 0);
  }

  useEffect(() => {
    setSlideChanged(true)
    if(location.state===null){
      navigate('/')
      return
    }
    const imageCaptured =location.state.photo
    setDataImage(imageCaptured);

    paletteData = data.seasonal2Color;
    setPalette(paletteData);
    
    let seasonal=  location.state.seasonal;
    setSeasonalState(seasonal);

    if(seasonal === 'warm'){
      setCheckedPalette(paletteData[0]);
    }else{
      setCheckedPalette(paletteData[2]);
      setIndexSelectedPalette(2)
    }

    disabledSeasonal(seasonal)
    sendAnalyticsData('View Page','Seasonal2 Page')
  }, []);

  const disabledSeasonal = (seasonal) =>{
    if(seasonal === 'warm'){
      setSectionDisabled([2, 3])
    }else{
      setSectionDisabled([0, 1])
    }
  }

  const changeSlide = (index) => {
    refSlider.current.slickGoTo(index);
    setCurrentSlide(index);
  }

  const renderDataImage = () =>{
    if(dataImage){
      return (
        <>
          <SideInfo title={'Check your seasonal color 2/3'} description={'Choose a color that makes your skin brighter and softens the color around your dark circles & smile lines'}/>
          {/* <div className='box-captured'> */}
            <div className='image-captured-wrapper'>
              <img className='face-bg' src={!checkedPalette ? '/images/face-bg.png' : '/images/seasonal-2/'+checkedPalette.name+'/face-bg-'+checkedPalette.palette[currentSlide].color.replace('#', '')+'.png'} alt=''/>
              <img className='image-captured' src={dataImage} alt="Captured" />
              {checkedPalette ?
                <Carousel innerRef={refSlider} className={`seasonal-slider ${slideChanged ? 'opacity-1' : 'opacity-0'}`} items={checkedPalette.palette} pageSection={'seasonal-2'} seasonal={checkedPalette.name}/>
              :
                null
              }
              <p className='section-step'>Check your seasonal color 2/3</p>
              <div className='camera-hint d-block d-desktop-none'>Choose a color that makes your skin brighter and<br/>softens the color around your dark circles &amp; smile lines</div>
            </div>
          {/* </div> */}

          <div className='camera-colorpalette-wrapper'>
            <div className='title-sidebox d-none d-desktop-block'>SEASONAL COLOR</div>
            <div className='camera-color-wrapper flex-row'>
              {palette && palette.map((sectionColor, index) => {
                return(
                  <div key={index} className={`section-color radio-group ${sectionDisabled.map(i => i === index ? 'disabled' : '').join('') }`}>
                    <label className='d-flex flex-column'>
                      <input type="radio" name="radio-group" value={sectionColor.name} defaultChecked={index === (seasonalState === 'warm' ? 0 : 2)} onChange={() => { switchSectionColor(sectionColor.name, index) }}/>
                      <span className="radio-custom"></span>
                      <span className='d-flex flex-column w-100 gap-1'>
                        {sectionColor && sectionColor.palette.map(function (bg, index) {
                          return <div key={index} className={`seasonal-palette ${currentSlide === index ? 'active ' : ''}`} style={{ backgroundColor: bg.color }} onClick={() => changeSlide(index)}></div>
                        })}
                      </span>
                    </label>
                  </div>
                )
              })
            }
            </div>
            <Link className={`btn btn-primary d-none d-desktop-table mx-auto mt-4 w-100 ${buttonDisabled ? 'disabled' : ''}`} onClick={()=>{sendAnalyticsData('Click','Go To Seasonal3 Page')}} state={{photo:location.state.photo,skintone:location.state.skintone,undertone1:location.state.undertone1,undertone2:location.state.undertone2,undertone3:location.state.undertone3,seasonal:location.state.seasonal,seasonal1:location.state.seasonal1,seasonal2:indexSelectedPalette}} to={`/${type}/seasonal-3`}>Next</Link>
          </div>
          <Link className={`btn-next btn btn-primary d-table d-desktop-none mx-auto w-100 ${buttonDisabled ? 'disabled' : ''}`} onClick={()=>{sendAnalyticsData('Click','Go To Seasonal3 Page')}} state={{photo:location.state.photo,skintone:location.state.skintone,undertone1:location.state.undertone1,undertone2:location.state.undertone2,undertone3:location.state.undertone3,seasonal:location.state.seasonal,seasonal1:location.state.seasonal1,seasonal2:indexSelectedPalette}} to={`/${type}/seasonal-3`}>Next</Link>
        </>
      )
    }else{
      return <p className='text-center'>Photo not found</p>
    }
  }

  return (
    <div className='full-height'>
      <Header title={'SEASONAL COLOR'} className='d-flex d-desktop-none'/>
      <Step activeLength={[6, 7]}/>
      <div className='camera-palette-wrapper d-flex flex-column'>
        <div className='navigation'>
          <Link className='back' onClick={() => navigate(-1)} state={{state:location.state}}>
            <BiSolidLeftArrow/>
          </Link>
          <Link to='/'>
            <BiX/>
          </Link>
        </div>
        {renderDataImage()}
      </div>
      <Header className='d-none d-desktop-flex'/>
      <img className='petal-result-bg' src='/images/petal-page-2.png' alt=''/>
    </div>
  );
};

export default PageSeasonal2;
