import React from 'react';

const Step = ({activeLength}) => {
  var steps = [];
  var active = activeLength[0];
  var length = activeLength[1];
  const renderStep = () => {
    for(let step = 1; step < length+1; step++) {
      steps.push(<span key={`step-${step}`} className={`step-progress step-${step} ${step === active ? 'active' : ''}`}></span>)
    }

    return steps;
  }

  return (
    <div className='step-progress'>
      {renderStep()}
    </div>
  );
};

export default Step;