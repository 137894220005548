import React from 'react';
const SideInfo = ({title, description, className}) => {
  return (
    <>
      <div className={`side-info flex-column d-none d-desktop-flex flex-wrap ${className}`}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </>
  );
};

export default SideInfo;
