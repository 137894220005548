import React from 'react';
import { BiX } from 'react-icons/bi';

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup-content">
        <a className="close-button" onClick={onClose}>
          <BiX/>
        </a>
        {children}
      </div>
    </div>
  );
};

export default Popup;
