import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate,useLocation } from 'react-router-dom';
import { BiSolidLeftArrow, BiX } from 'react-icons/bi';
// import {getStateValue,isStateExist} from '../components/BrowserHistory.js'
import Header from "../components/Header";
import Step from "../components/Step";
import SideInfo from "../components/SideInfo";

import data from '../json/palette.json'
import { sendAnalyticsData } from '../utilities/analytics';
const PageCapturedPalette = () => {
 
  let paletteData = [];


  const [palette, setPalette] = useState([]);
  const [dataImage, setDataImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();
  useEffect(() => {
    paletteData = data.skintone_palette;
    setPalette(paletteData);
  
    if(location.state===null){
      navigate('/')
      return
    }
    sendAnalyticsData('View Page','SkinTone Page');
    setDataImage(location.state.photo);
 
    setSelectedColor(paletteData[0]);
  }, []);

  const selectColor = (color) => {

    setSelectedColor(color);
    setButtonDisabled(false);
  }

  const renderDataImage = () =>{
    if(dataImage){
      return (
        <>
          <SideInfo title={'Check your skin tone'} description={'Choose a color that makes your skin brighter and softens the color around your dark circles & smile lines'}/>
          <div className='image-captured-wrapper'>
            <img className={`face-bg`} src='/images/face-bg.png' alt=''/>
            <img className={`face-bg`} src={`/images/skintone/face-bg-${selectedColor.replace('#', '')}.png`} alt=''/>
            <img className='image-captured' src={dataImage} alt="Captured" />
            <div className='camera-hint d-block d-desktop-none'>Please identify with natural bright light</div>
          </div>
          <div className='camera-colorpalette-wrapper pb-2 pb-md-3 pb-lg-4'>
            <div className='title-sidebox d-none d-desktop-block'>SKIN TONE</div>
            <div className='camera-color-wrapper'>
              {palette.slice(0, 4).map((color, index) => (
                <div key={index} className={`camera-palette ${selectedColor === color ? 'active' : ''}`} onClick={() => { selectColor(color) }} style={{ backgroundColor: color }}></div>
              ))}
            </div>
            <div className='camera-color-wrapper'>
              {palette.slice(-4).map((color, index) => (
                <div key={index} className={`camera-palette ${selectedColor === color ? 'active' : ''}`} onClick={() => { selectColor(color) }} style={{ backgroundColor: color }}></div>
              ))}
            </div>
            <Link className={`btn btn-primary d-none d-desktop-table mx-auto mt-3 w-100 ${buttonDisabled ? 'disabled' : ''}`} onClick={() => {sendAnalyticsData('Click','Go To Undertone1 Page')}} state={{photo:location.state.photo,skintone:selectedColor}} to={`/${type}/undertone-1`}>Next</Link>
          </div>
          <Link className={`btn-next btn btn-primary d-table d-desktop-none mx-auto w-100 ${buttonDisabled ? 'disabled' : ''}`} onClick={() => {sendAnalyticsData('Click','Go To Undertone1 Page')}} state={{photo:location.state.photo,skintone:selectedColor}} to={`/${type}/undertone-1`}>Next</Link>
        </>
      )
    }else{
      return <p className='text-center'>Photo not found</p>
    }
  }

  return (
    <div className='full-height'>
      <Header title={'SKIN TONE'} className='d-flex d-desktop-none'/>
      {type === 'basic' ?
        <Step activeLength={[1, 4]}/>
      :
        <Step activeLength={[1, 7]}/>
      }
      <div className='camera-palette-wrapper d-flex flex-column'>
        <div className='navigation'>
          <Link className='back' onClick={() => navigate(-1) } state={{state:location.state}}>
            <BiSolidLeftArrow/>
          </Link>
          <Link to='/'>
            <BiX/>
          </Link>
        </div>
        {renderDataImage()}
      </div>
      <Header className='d-none d-desktop-flex'/>
      <img className='petal-result-bg' src='/images/petal-page-2.png' alt=''/>
    </div>
  );
};

export default PageCapturedPalette;
