var linkAPI=process.env.REACT_APP_API
function throwErrorMessage(res){
    console.log(res);
    if(res.status===500)throw res
    else{
        
        return res
    }
}
const API={
    uploadImage: async function(formData){
        try {
            const requestOptions = {
                method: "POST",
                mode:'cors',
                body: formData
              };
            let res = await fetch(linkAPI+'/upload-image',requestOptions)
              .then(response => response.json())
            return throwErrorMessage(res)
        } catch (error) {
            console.log(error);
            if(error.status===500) throw error
            throw {
                message:[error.message]
            }
        }
    },
    submitEmail: async function(formData){
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                mode:'cors',
                body: JSON.stringify(formData)
              };
            let res = await fetch(linkAPI+'/submit-email',requestOptions)
              .then(response => response.json())
            return throwErrorMessage(res)
        } catch (error) {
            if(error.status===500) throw error
            throw {
                message:[error.message]
            }
        }
    }
}

export default API;