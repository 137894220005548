import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

const SlickButton = ({currentSlide, slideCount, children, ...props}) => (
  <a {...props}>{children}</a>
);

const Carousel = ({items, pageSection, seasonal, className, innerRef}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    arrow: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: false,
    nextArrow: 
      <SlickButton className="slick-arrow slick-next">
          <BiChevronRight/>
      </SlickButton>,
    prevArrow: 
      <SlickButton className="slick-arrow slick-prev">
          <BiChevronLeft/>
      </SlickButton>
  };

  const carouselItems =
    Array.isArray(items)
      ? items.map((item, index) => {
          var hex = item.color.replace('#', '');

          return <img key={index} className="face-bg w-auto" src={`/images/${pageSection}/${seasonal}/face-bg-${hex}.png`} alt={`image ${index}`} />
        })
      : null;

  return <Slider ref={innerRef} className={`box-captured ${className}`} key={`slider ${seasonal}`} {...settings}>{carouselItems}</Slider>;
};

export default Carousel;
