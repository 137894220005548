import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

if(window.self === window.top) {

  root.render(
      <App />
  );
} else{
  try {
    if(window.top[0].location.ancestorOrigins===undefined){
      root.render(
        // <React.StrictMode>
        <div className='in-iframe'>
          <App />
        </div>
        // </React.StrictMode>
      );
      
    }else if(window.top[0].location.ancestorOrigins[0]===process.env.REACT_APP_DOMAIN){
      // console.log(window.top[0].location.ancestorOrigins[0]);
      root.render(
        // <React.StrictMode>
        <div className='in-iframe'>
          <App />
        </div>
        // </React.StrictMode>
      );
  
    }else{
      try {
        // console.log(process.env.REACT_APP_DOMAIN);
        // window.top.location= process.env.REACT_APP_DOMAIN
        root.render(
          <App />
        );
      } catch (error) {
        console.error(error.message)
      }
  
    }
  } catch (error) {
      console.log(error)
  }
 

}


 
// Won't render the application if WebApp is under Clickjacking attack

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
