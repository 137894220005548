// src/Camera.js
import React ,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { sendAnalyticsData } from '../utilities/analytics';
import Cookies from 'universal-cookie';

const PageLanding = (e) => {
  
  const settings = {
    dots: false,
    infinite: true,
    accessibility: false,
    arrows: false,
    speed: 1800,
    autoplaySpeed: 3000,
    fade: true,
    slidesToShow: 1,
    autoplay: true
  };
  useEffect(() => {
    const cookies = new Cookies();
    let event_name = new URL(window.location.href).searchParams.get('event_name');
    if (event_name) {
      cookies.set('event_name', event_name, {path: '/', expires: new Date(Date.now() + 24*3600*1000)});
    }
    sendAnalyticsData('View Page','Landing Page')
  }, [])
  
  return (
    <div className='landing-container'>
      <Slider className='bg-landing-slider' {...settings}>
        <div className='bg-landing bg-landing0'></div>
        <div className='bg-landing bg-landing1'></div>
        <div className='bg-landing bg-landing2'></div>
        <div className='bg-landing bg-landing3'></div>
        <div className='bg-landing bg-landing4'></div>
      </Slider>

      <div className='intro-landing'>
        <div className='landing-title'>
          <img className='logo-landing' src='/images/logo.png' alt=''/>
          <h1>Find Your<br/>Personal Color</h1>
          <h3>Get to know your&nbsp;<br className='d-sm-none d-lg-block'/>personal color to find the<br/>colors that suit you best!</h3>
        </div>
        <div className='landing-button d-flex flex-column w-100'>
          <Link to='/basic/hint'  onClick={()=>{sendAnalyticsData('Click','Go to Basic Page')}} className='btn btn-primary mb-3'>
            Basic
            <span>4 steps</span>
          </Link>
          <Link to='/expert/hint' onClick={()=>{sendAnalyticsData('Click','Go to Real Expert Page')}}  className='btn btn-primary'>
            Expert
            <span>7 steps</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageLanding;
