import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate ,useLocation} from 'react-router-dom';
import { BiSolidLeftArrow, BiX } from 'react-icons/bi';


import Header from "../components/Header";
import Step from "../components/Step";
import SideInfo from "../components/SideInfo";

import data from '../json/palette.json'
import { sendAnalyticsData } from '../utilities/analytics';

const PageUnderTone3 = () => {


  let paletteData = [];

  const [palette, setPalette] = useState([]);
  const [dataImage, setDataImage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#56372D')
  
  const [ seasonal,setSeasonal] = useState('')
  const navigate = useNavigate();
  const location = useLocation()
  const { type } = useParams();

  useEffect(() => {
    paletteData = data.palette3
    setPalette(paletteData);
    if(location.state===null){
      navigate('/')
      return
    }
    
    const imageCaptured =location.state.photo
    setDataImage(imageCaptured)
    sendAnalyticsData('View Page','UnderTone3')
    checkSeasonal(setSeasonal,'#56372D')
  }, []);

 
  const checkSeasonal=(setSeasonal,color)=>{
    let seasonal ='warm'
    let coolCount = 0
    let coolPalette = data.coolpalette
    let arrColor = [location.state.undertone1,location.state.undertone2,color]

    for(let i =0;i<coolPalette.length;i++){
      if(arrColor.includes(coolPalette[i])){
        coolCount=coolCount+1
      }
    }
    if(coolCount>=2){
      seasonal='cool'
    }
    setSeasonal(seasonal)
  }
  
  const changeBg = (color) => {
    setSelectedColor(color);
    setButtonDisabled(false);
    
    checkSeasonal(setSeasonal,color)
  }

  const renderDataImage = () =>{
    if(dataImage){
      return (
        <>
          <SideInfo title={'Check your undertone 3/3'} description={'Choose a color that makes your skin brighter and softens the color around your dark circles & smile lines'}/>
          <div className='image-captured-wrapper image-captured-wrapper2'>
            <img className='image-captured' src={dataImage} alt="Captured" />
            <img className={`face-bg`} src='/images/face-bg.png' alt=''/>
            {selectedColor ?
              <img className='face-bg' src={`/images/undertone-3/face-bg-${selectedColor.replace('#', '')}.png`} alt=''/>
              :
              null
            }
            <p className='section-step'>Check your undertone color 3/3</p>
            <div className='camera-hint d-block d-desktop-none'>Choose a color that makes your skin brighter and<br/>softens the color around your dark circles &amp; smile lines</div>
          </div>

          <div className='camera-colorpalette-wrapper'>
            <div className='title-sidebox d-none d-desktop-block'>UNDER TONE</div>
            <div className='camera-color-wrapper'>
              {palette.map((color, index) => (
                <div key={index} className={`undertone-palette ${selectedColor === color ? 'active' : ''}`} onClick={() => {changeBg(color)}} style={{ backgroundColor: color }}></div>
              ))}
            </div>
            {type === 'expert' ?
              <Link className={`btn btn-primary d-none d-desktop-table mx-auto mt-4 w-100 ${buttonDisabled ? 'disabled' : ''}`} to={`/${type}/seasonal-1`} onClick={()=>{sendAnalyticsData('Click','Go To Seasonal1 Page')}} state={{photo:location.state.photo,skintone:location.state.skintone,undertone1:location.state.undertone1,undertone2:location.state.undertone2,undertone3:selectedColor,seasonal:seasonal}}>Next</Link>
              :
              <Link className={`btn btn-primary d-none d-desktop-table mx-auto mt-4 w-100 ${buttonDisabled ? 'disabled' : ''}`} to={`/${type}/result`} onClick={()=>{sendAnalyticsData('Click','Go To Result Page')}} state={{photo:location.state.photo,skintone:location.state.skintone,undertone1:location.state.undertone1,undertone2:location.state.undertone2,undertone3:selectedColor,seasonal:seasonal}}>Next</Link>
            }
          </div>
          {type === 'expert' ?
            <Link className={`btn-next btn btn-primary d-table d-desktop-none mx-auto w-100 ${buttonDisabled ? 'disabled' : ''}`} to={`/${type}/seasonal-1`} onClick={()=>{sendAnalyticsData('Click','Go To Seasonal1 Page')}} state={{photo:location.state.photo,skintone:location.state.skintone,undertone1:location.state.undertone1,undertone2:location.state.undertone2,undertone3:selectedColor,seasonal:seasonal}}>Next</Link>
            :
            <Link className={`btn-next btn btn-primary d-table d-desktop-none mx-auto w-100 ${buttonDisabled ? 'disabled' : ''}`} to={`/${type}/result`} onClick={()=>{sendAnalyticsData('Click','Go To Result Page')}} state={{photo:location.state.photo,skintone:location.state.skintone,undertone1:location.state.undertone1,undertone2:location.state.undertone2,undertone3:selectedColor,seasonal:seasonal}}>Next</Link>
          }
        </>
      )
    }else{
      return <p className='text-center'>Photo not found</p>
    }
  }

  return (
    <div className='full-height'>
      <Header title={'UNDERTONE'} className='d-flex d-desktop-none'/>
      {type === 'basic' ?
        <Step activeLength={[4, 4]}/>
      :
        <Step activeLength={[4, 7]}/>
      }
      <div className='camera-palette-wrapper d-flex flex-column'>
        <div className='navigation'>
          <Link className='back' onClick={() => navigate(-1)} state={{state:location.state}}>
            <BiSolidLeftArrow/>
          </Link>
          <Link to='/'>
            <BiX/>
          </Link>
        </div>
        {renderDataImage()}
      </div>
      <Header className='d-none d-desktop-flex'/>
      <img className='petal-result-bg' src='/images/petal-page-2.png' alt=''/>
    </div>
  );
};

export default PageUnderTone3;
