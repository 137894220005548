// src/Camera.js
import React, {useState,useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BiSolidLeftArrow, BiX } from 'react-icons/bi';
import Header from "../components/Header";
import Popup from '../components/Popup';

import { sendAnalyticsData } from '../utilities/analytics';
const PageHint = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  // const [isChecked2, setIsChecked2] = useState(false);

  const navigate = useNavigate();
  const { type } = useParams();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    
    if(!isChecked){
      setButtonDisabled(false)
    }else{
      setButtonDisabled(true);
    }
  };

  // const handleCheckboxChange2 = () => {
  //   setIsChecked2(!isChecked2);

  //   if(isChecked && !isChecked2){
  //     setButtonDisabled(false)
  //   }else{
  //     setButtonDisabled(true);
  //   }
  // };

  const togglePopup = (type) => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    sendAnalyticsData('View Page','Hint Page')
  }, [])
  
  return (
    <div className='full-height'>
      <Header/>
      <div className='hint-wrapper d-flex flex-column'>
        <div className='hint-title d-none d-desktop-block'>
          <div className='navigation'>
            <Link className='back' onClick={() => navigate(-1)}>
              <BiSolidLeftArrow/>
            </Link>
            <Link to='/'>
              <BiX/>
            </Link>
          </div>
          TIPS FOR IDEAL<br/>CAMERA POSITION
        </div>
        <div className='hint-inner-wrapper'>
          <div className='hint-title d-block d-desktop-none'>
            <div className='navigation'>
              <Link className='back' onClick={() => navigate(-1)}>
                <BiSolidLeftArrow/>
              </Link>
              <Link to='/'>
                <BiX/>
              </Link>
            </div>
            TIPS FOR IDEAL<br/>CAMERA POSITION
          </div>
          <div className='hint-box-wrapper'>
            <div className='hint-box'>
              <p>LIGHTING CHECK</p>
              <span>GOOD</span>
            </div>
            <div className='hint-box'>
              <p>LOOK STRAIGHT</p>
              <span>GOOD</span>
            </div>
            <div className='hint-box'>
              <p>POSITION FACE</p>
              <span>GOOD</span>
            </div>
          </div>
          <img className='hint-image' src='/images/home-hint.png' alt=''/>
        </div>
        <Link className='btn btn-primary d-table mx-auto w-100' onClick={() => {togglePopup('basic');  sendAnalyticsData('Click','Start at Hint Page')}}>Start</Link>
      </div>
      
      <Popup isOpen={isOpen} onClose={togglePopup} type={type}>
        <div className='term-condition py-4 px-4'>
          <h2 className='mb-4'>
            OUR<br/>
            <span>PRIVACY POLICY</span>
          </h2>
          <div className='term-condition-check pb-2 mb-4'>
            <label className='checkbox'>
              <input
                name='tnc'
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span>
                I have read, understand and accept the <Link to='https://www.wardahbeauty.com/en/privacy-policy' target='_blank' onClick={() => {sendAnalyticsData('Click','Privacy Policy Link')}}>privacy policy</Link>
              </span>
            </label>
            {/* <label className='checkbox mb-3'>
              <input
                name='tnc2'
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Saya mengerti dan menerima kebijakan privacy policy
            </label> */}
          </div>
          {type === 'basic' ?
            <Link to={`/basic/captured`}  onClick={() => {sendAnalyticsData('Click','Privacy Policy Button')}} className={`btn btn-primary w-100 ${buttonDisabled ? 'disabled' : ''}`}>
              Next
            </Link>
          :
            <Link to={`/expert/captured`}  onClick={() => {sendAnalyticsData('Click','Privacy Policy Button')}} className={`btn btn-primary w-100 ${buttonDisabled ? 'disabled' : ''}`}>
              Next
            </Link>
            
           } 
        </div>
      </Popup>
    </div>
  );
};

export default PageHint;
