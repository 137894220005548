import amplitude from 'amplitude-js';
import ReactGA from "react-ga4";
import { clarity } from 'react-microsoft-clarity';
export const initAnalytics = () => {
  // console.log(process.env.REACT_APP_AMPLITUDE);
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
  ReactGA.initialize(process.env.REACT_APP_GA);
  clarity.init(process.env.REACT_APP_CLARITY);
};

// export const setAmplitudeUserDevice = installationToken => {
//   amplitude.getInstance().setDeviceId(installationToken);
// };

// export const setAmplitudeUserId = userId => {
//   amplitude.getInstance().setUserId(userId);
// };

// export const setAmplitudeUserProperties = properties => {
//   amplitude.getInstance().setUserProperties(properties);
// };

export const sendAnalyticsData = (action, description,param) => {
  if(param!==undefined){
    amplitude.getInstance().logEvent(action +' '+ description,param);
  }else{
    amplitude.getInstance().logEvent(action +' '+ description);
  }
  ReactGA.event({
    category:description,
    description: description,
    action: action,
  });
  clarity.setTag(action, description);
};