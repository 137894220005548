// src/Camera.js
import React from 'react';
import { useNavigate, Link,useLocation} from 'react-router-dom';
import { BiSolidLeftArrow, BiX } from 'react-icons/bi';

import Camera from "../components/Camera";
import Header from '../components/Header';
import { sendAnalyticsData } from '../utilities/analytics';
const PageBasic = () => {
  sendAnalyticsData('View Page','Captured')
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='full-height'>
      <Header className='d-flex d-desktop-none'/>
      <div className='navigation d-none d-desktop-flex w-100 pt-2'>
        <Link className='back' onClick={() => navigate(-1,{state:location.state})}>
          <BiSolidLeftArrow/>
        </Link>
        <Link to='/'>
          <BiX/>
        </Link>
      </div>
      <div className='camera-palette-wrapper d-flex flex-column justify-content-center' style={{height: 'calc(100vh - 48px - 5vh)'}}>
        <Camera>
          <div className='navigation d-flex d-desktop-none'>
            <Link className='back' onClick={() => navigate(-1)} state={{state:location.state}}>
              <BiSolidLeftArrow/>
            </Link>
            <Link to='/'>
              <BiX/>
            </Link>
          </div>
        </Camera>
      </div>
      <Header className='d-none d-desktop-flex'/>
      <img className='petal-result-bg' src='/images/petal-page-2.png' alt=''/>
    </div>
  );
};

export default PageBasic;
