// src/Camera.js
import React, { useEffect, useState ,} from 'react';
import { Link,useParams,useNavigate  ,useLocation} from 'react-router-dom';
import data from '../json/palette.json'
import API from "../API/api.js";
import { sendAnalyticsData } from '../utilities/analytics';
import Cookies from 'universal-cookie';

const PageResult = () => {


  const [result, setResult] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // const [btnDisabled, setbtnDisabled] = useState(false);

  
  const [key,setKey]= useState('')
  const [subseasonal,setSubseasonal]= useState('')
  const [isPhoneNumber,setIsPhoneNumber]= useState('nonfocus')
  const [isEmail,setIsEmail]= useState('nonfocus')
  const [isName,setIsName]= useState('nonfocus')
  var subseasonalState=''
  const handleCheckboxChange = () => {
    if(isPhoneNumber==='true'&& isEmail==='true' && isName==='true')
    setIsChecked(!isChecked);
  };

  const { type } = useParams();
  const navigate = useNavigate()
  const location = useLocation()

  // console.log(type);
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }
  function checkComplexionName(color){
      let complexionNameArr = data.complexion_name
      return complexionNameArr[color]
  }
  async function uploadImage(callback,setKey,setSubseasonal){
    try {
      const formData = new FormData();
      if( !location.state.photo){
        navigate('/')
        return
      }
      if(location.state.seasonal===undefined ||
      location.state.undertone1===undefined ||
      location.state.undertone2===undefined ||
      location.state.undertone3===undefined ||
      location.state.skintone===undefined 
      ){
        navigate('/'+type+'/palette')
        return
      }
    
      let seasonal =location.state.seasonal
      formData.append('image', dataURLtoFile(location.state.photo,'capture'));
      formData.append('fSkinTone',location.state.skintone)
      formData.append('fUndertone1',location.state.undertone1);
      formData.append('fUndertone2',location.state.undertone2);
      formData.append('fUndertone3',location.state.undertone3);
      formData.append('fSeasonalType', seasonal);
      formData.append('fType', type);
      formData.append('fSource', 'general');
      if(type==='expert'){
        if(location.state.seasonal1===undefined ||
        location.state.seasonal2===undefined ||
        location.state.seasonal3===undefined 
        
        ){
          navigate('/'+type+'/palette')
          return
          
        }
        let arrSeasonal = [location.state.seasonal1,location.state.seasonal2,location.state.seasonal3]
      
        if(seasonal==='warm'){
          let arr = arrSeasonal.filter((item,index)=>{
            return item ===0
          })
   
          if(arr.length>=2){
            subseasonalState='spring'
            setSubseasonal('spring')
  ;
          }else{
            subseasonalState='autumn'
            setSubseasonal('autumn')



          }
        }else{
          let arr = arrSeasonal.filter((item,index)=>{
            return item ===2
          })
          if(arr.length>=2){
            subseasonalState='summer'
            setSubseasonal('summer')


          }else{
            subseasonalState='winter'
            setSubseasonal('winter')


          }
        }
        
        formData.append('fSeasonal1',  location.state.seasonal1);
        formData.append('fSeasonal2',  location.state.seasonal2);
        formData.append('fSeasonal3',  location.state.seasonal3);
      
      }
 
      let res = await API.uploadImage(formData)

   
      // return res
      if(res.status===200){
        
        setKey(res.data.fID)


        callback(true)
        sendAnalyticsData('API Success','Upload Images')

      }

    } catch (error) {
        sendAnalyticsData('API Failed','Upload Images')

        console.error(error)
    }


  }
  async function submitEmail(formData,key){
    try {
      const cookies = new Cookies();
      let data={
        "fResultKey": key,
        "fName":formData.name,
        "fEmail":formData.email,
        "fPhone":formData.phone,
        "fEventSource": cookies.get('event_name')
      }
      console.log('DATA:',data);
      // setbtnDisabled(true)
    let res = await API.submitEmail(data)
    if(res.status===200){
      // localStorage.removeItem('fID')
      // localStorage.setItem('name', formData.name)

      sendAnalyticsData('API Success','Submit Email')
      sendAnalyticsData('Click','Go To Result Template Page')
      
      navigate('/'+type+'/result-template',{state:{subseasonal:subseasonal,name:formData.name,complexionName:checkComplexionName(location.state.skintone), skintone: location.state.skintone,seasonal:location.state.seasonal}})
      return
    }
    // console.log('failed send email');
    // return res
    } catch (error) {
      // setbtnDisabled(false)
      if(error.message[0]==='Already submitted'){
        // localStorage.removeItem('fID')
        // console.log('next page')
        
        navigate('/'+type+'/result-template',{state:{subseasonal:subseasonal,name:formData.name}})
        return
      }
      sendAnalyticsData('API Failed','Submit Email')

      // navigate('/'+type+'/result-template')
      // console.log('wew');
    }
 
  }
  useEffect(() => {
  
    if(!location.state){
      navigate('/'+type+'/palette')
      return
    }
  
    uploadImage(setResult,setKey,setSubseasonal)
    sendAnalyticsData('View Page','Result Page')
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(object);
    submitEmail(formData,key)
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if(name==='email'){
      if(!regexEmail(value)){
        setIsChecked(false)
        setIsEmail('false')
        return
      }else{
        setIsEmail('true')
      }
    }
    if(name==='phone'){
      if(!regexPhone(value)){
        setIsChecked(false)
        setIsPhoneNumber('false')
        return
      }else{
        setIsPhoneNumber('true')
      }
    }
    if(name==='name'){
      if(!regexName(value)){
        setIsChecked(false)
        setIsName('false')
        return
      }else{
        setIsName('true')
      }
    }
  };
  const regexEmail=(email)=>{
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  }
  const regexPhone=(phone)=>{
    return /^(\+62|62|08)[1-9][0-9]{7,9}$/.test(phone)
  }
  const regexName = (name)=>{
    return /^[a-zA-Z ]+$/.test(name)
  }
  return (
    <>
      <div className='container container-loading-result'>
        <div className='full-height result-form-wrapper'>
          <div className='page-wrapper d-flex justify-content-center align-items-center flex-column text-center'>
            <Link className='logo-form' to='/'>
              <img src='/images/logo.png' alt=''/>
            </Link>
          {!result ? 
            <>
              <div className='loading-result-intro d-flex flex-column align-items-center justify-content-center'>
                <div id="circularG" className='mb-4'>
                  <div id="circularG_1" className="circularG"></div>
                  <div id="circularG_2" className="circularG"></div>
                  <div id="circularG_3" className="circularG"></div>
                  <div id="circularG_4" className="circularG"></div>
                  <div id="circularG_5" className="circularG"></div>
                  <div id="circularG_6" className="circularG"></div>
                  <div id="circularG_7" className="circularG"></div>
                  <div id="circularG_8" className="circularG"></div>
                </div>
                <div className='loading-result-title'>
                  <h2 className='mb-3'>Analyzing Your Personal Color</h2>
                  <h3>please wait a moment</h3>
                </div>
              </div>
              <img className='blur-bg-res d-block d-desktop-none' src='/images/bg-result-mobile.png' alt=''/>
              <img className='blur-bg-res d-none d-desktop-block' src='/images/bg-result-desktop.png' alt=''/>
            </>
            :
            <>
              <form className='form-result' onSubmit={handleSubmit}>
                <input className='form-control text-center' type="text" placeholder="your name" name='name' value={formData.name} onChange={handleChange} required/>
                <p className={`error-validation ${isName === 'false' ? 'opacity-1' : 'opacity-0'}`}>Name must not be empty</p>
                
                <input className='form-control text-center' type="email" placeholder="your email" name='email' value={formData.email} onChange={handleChange} required/>
                <p className={`error-validation ${isEmail === 'false' ? 'opacity-1' : 'opacity-0'}`}>Email is not valid</p>

                <input className='form-control text-center' type="text" placeholder="your phone number" name='phone' value={formData.phone} onChange={handleChange} required/>
                <p className={`error-validation ${isPhoneNumber === 'false' ? 'opacity-1' : 'opacity-0'}`}>Phone number is not valid</p>

                <label className='checkbox'>
                  <input
                    name='tnc2'
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  I agree to receive personalized commercial communications from Wardah
                </label>
                <button className={`btn btn-primary btn-lg w-100 ${!isChecked  ? 'disabled' : ''}`} type='submit'>Submit</button>
              </form>
              <img className='petal-result-bg' src='/images/petal-page-2.png' alt=''/>
            </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PageResult;