// src/Camera.js
import React, { useRef, useState, useEffect, Children } from 'react';
import Webcam from 'react-webcam';
import { Link, useParams,useNavigate  } from 'react-router-dom';
import { HiOutlineCamera } from 'react-icons/hi2';
import { PiWarningLight } from 'react-icons/pi'

import { sendAnalyticsData } from '../utilities/analytics';
// import {setStateValue,removeStateValue} from './BrowserHistory.js'
const videoConstraints = {
  width: 1280,
  height: 1280,
  facingMode: "user"
};

const Camera = (props, {hint}) => {
  const [initCamera, setInitCamera] = useState(false);
  const [cameraAccessBlocked, setCameraAccessBlocked] = useState(false);
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [timerText, setTimerText] = useState(3);
  const [resolution,setResolution] = useState({width:1280,height:1280})
  const [timerStart, setTimerStart] = useState(false);
  const { type } = useParams();
  const navigate = useNavigate()
  // const history = createBrowserHistory()

  // console.log(createBrowserHistory);
  const startCapture = () => {
    sendAnalyticsData('Click','Photo Button')
    setTimerStart(true)
  }
  
  const checkCameraAccess = async () => {
    clearTimeout(showCameraTimeout);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      let stream_settings = stream.getVideoTracks()[0].getSettings();
      let smaller = Math.min(stream_settings.width,stream_settings.height)
      if(smaller>720){

        setResolution({width:1280,height:1280})
      }else{
        if(smaller<=600){
          setResolution({width:720,height:720})
          videoConstraints.width=smaller
          videoConstraints.height=smaller
        }else{
          setResolution({width:720,height:720})
          videoConstraints.width=720
          videoConstraints.height=720
        }

      }
      console.log(stream_settings);
      stream.getTracks().forEach((track) => track.stop());

 
      setInitCamera(true)
      setCameraAccessBlocked(false);

      var showCameraTimeout = setTimeout(() => {
        if(document.querySelector('.video')){
          document.querySelector('.video').classList.remove('opacity-0');
        }
      }, 2000);

      sendAnalyticsData('Click','Allowed Camera')
    } catch (error) {

      if (error.name === 'NotAllowedError') {
        sendAnalyticsData('Click','Not Allowed Camera')
        setCameraAccessBlocked(true);
      }
    }
  };

  useEffect(() => {
    let interval;

    if (timerStart) {
      interval = setInterval(() => {
        setTimerText((prevTimerText) => {
          if (prevTimerText === 1) {
            clearInterval(interval); // Clear the interval when timerText reaches zero
            captureImage();

            return 0;
          } else {
            return prevTimerText - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };

    
  }, [timerStart]);

  useEffect(() => {
    checkCameraAccess();
  }, []);

  const captureImage = () => {
    const imageCaptured = webcamRef.current.getScreenshot({width: resolution.width, height: resolution.height});
    setImageSrc(imageCaptured);
    sendAnalyticsData('Click','Photo Taken')
    // setStateValue('photo',imageCaptured,location)

    // localStorage.setItem('photo', imageCaptured)

  };
  
  const hintText = !hint ? 'Please identify with natural bright light' : hint;

  const retakeEvent = () => {
    checkCameraAccess();
    sendAnalyticsData('Click','Retake Photo')
    setImageSrc(null);
    setTimerStart(false);
    setTimerText(3);
  }

  return (
    <>
    {
    !imageSrc ?
      <div className='camera-wrapper d-flex justify-content-center align-items-center'>
        {props.children}
        <img className='face-outline' src='/images/face-outline.png' alt=''/>
        {timerStart ?
          <div className='timer'>
            Take photo in
            <span>{timerText}</span>
          </div>
          :
          null
        }
        
        {cameraAccessBlocked ? (
          <p className='camera-blocked'>
            <span><PiWarningLight/></span>
            Camera access is blocked, Please allow camera access in your browser settings.
            <a className='btn d-table mx-auto btn-primary btn-outline mt-2' onClick={()=>{checkCameraAccess()}}>Reload</a>
          </p>
        ) : 
          initCamera ? 
          (
          <Webcam
          className='video opacity-0'
          audio={false}
          ref={webcamRef}
          minScreenshotHeight={resolution.width}
          minScreenshotWidth={resolution.height}
          mirrored={true}
          screenshotFormat="image/jpeg" 
          videoConstraints={videoConstraints}
          />
          )
          :
            null
        }
        {!timerStart && !cameraAccessBlocked ?
          <div onClick={startCapture} className="capture-button d-flex justify-content-center align-items-center">
            <HiOutlineCamera style={{fontSize: '22px'}}/>
          </div>
          :
          null
        }
        <div className='camera-hint'>{hintText}</div>
      </div>
      :
      <div className='captured-wrapper'>
        <div className='captured-wrapper-inner'>
          <img className='image-captured' src={imageSrc} alt="Captured" />
        </div>
        <div className='captured-action d-flex flex-column mt-2 mt-md-3 mt-xl-0'>
          <a className='btn btn-primary btn-outline d-table mx-auto mb-2 mb-md-3 w-100' onClick={retakeEvent}>
            Retake
          </a>
          <Link className='btn btn-primary d-table mx-auto w-100' onClick={() => {sendAnalyticsData('Click','Go To SkinTone Page')}} to={`/${type}/palette`} state={{photo:imageSrc}}>Next</Link>
        </div>
      </div>
    }
    </>
  );
};

export default Camera;
